import * as React from "react"
import Seo from "../components/SeoComponent"
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl"
import {Link} from 'gatsby'
import '../assets/404.css'

const NotFoundPage = () => {
  const intl = useIntl()
  return(
    <>
      <Seo 
      lang={intl.locale}
      title={`404: ${intl.formatMessage({ id: "error" })}`}
      />
      <section className="error-container">
        <div className="error">
          <h1 className='error-txt'>
            404{' '}
            <FormattedMessage id="notfound.header" />
          </h1>
          <p className='error-para'>
            <FormattedMessage id="notfound.description" />
          </p>
          <Link to='/'>Back</Link>
        </div>
      </section>
    </>
  )
}

export default NotFoundPage
